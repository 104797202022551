<!--
* 创建人：罗兴
* 日 期：
* 描 述：综合测评得分查询
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">综合测评得分</div>
    <!--==============================按钮===================================================-->
    <div class="btngroup">
      <el-button
        type="primary"
        size="mini"
        @click="getDataPageList"
      >查询</el-button>
    </div>
    <div class="sm_search">
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div
            v-html="'学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;年'"
            class="sm_search_title"
          ></div>
          <el-select
            v-model="xn"
            style="display: block; width: 178px"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, index) in xnlist"
              :key="index"
              :label="item.ksnf + '-' + item.jsnf"
              :value="item.ksnf + '-' + item.jsnf"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!---->
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        stripe
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column
          prop="xh"
          label="学号"
          width="120"
        > </el-table-column>
        <el-table-column
          prop="xm"
          label="姓名"
          width="100"
        > </el-table-column>
        <el-table-column
          prop="xn"
          label="学年"
          width="160"
        > </el-table-column>
        <el-table-column
          prop="zypm"
          label="专业排名"
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="bjpm"
          label="班级排名"
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="zf"
          label="总分"
          width="80"
        > </el-table-column>
        <el-table-column
          prop="dy"
          label="德育分"
          width="80"
        > </el-table-column>
        <el-table-column
          prop="zy"
          label="智育分"
          width="80"
        > </el-table-column>
        <el-table-column
          prop="ty"
          label="体育分"
          width="80"
        > </el-table-column>
        <el-table-column
          prop="my"
          label="美育分"
          width="80"
        > </el-table-column>
        <el-table-column
          prop="ld"
          label="劳动分"
          width="80"
        > </el-table-column>
        <el-table-column
          prop="nl"
          label="能力分"
          width="80"
        > </el-table-column>
        <el-table-column
          prop="bmmc"
          label="学院"
          width="300"
        >
        </el-table-column>
        <el-table-column
          prop="zymc"
          label="专业"
          width="300"
        >
        </el-table-column>
        <el-table-column
          prop="bjmc"
          label="班级"
          width="300"
        >
        </el-table-column>
        <el-table-column
          prop="nj"
          label="年级"
        > </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="120"
        >
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row)"
              type="text"
            >打印综测单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="综合素质测评明细"
      :visible.sync="dialogFormVisible"
      width="50%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar
        style="height: 700px"
        class="myscrollbar"
      >
        <table
          cellpadding="0"
          cellspacing="0"
          class="table1"
        >
          <tr>
            <td>德育分</td>
            <td style="width:70px">{{ formdata.dy }}</td>
            <td>智育分</td>
            <td style="width:70px">{{ formdata.zy }}</td>
            <td>体育分</td>
            <td style="width:70px">{{ formdata.ty }}</td>
            <td>美育分</td>
            <td style="width:70px">{{ formdata.my }}</td>
            <td>劳动分</td>
            <td style="width:70px">{{ formdata.ld }}</td>
            <td>总分</td>
            <td style="width:70px">{{ formdata.zf }}</td>
          </tr>
          <tr>
            <td style="width:100px">得分明细</td>
            <td
              style="width:770px;"
              colspan="11"
            >
              <p
                v-for="(item, index) in list"
                :key="index"
              >
                <template v-if="item.lx==1">
                  {{ item.pfnr }}({{ item.fs }}分)
                </template>
                <template v-else>{{ item.pfnr }}(-{{ item.fs }}分)</template>
              </p>
            </td>
          </tr>
        </table>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>
<!-- javascript脚本-->
<script>
import { GetXSDFCXPageList, studentGetListByXN } from '../../api/zhcp'
import { getAuthorizeButtonColumnList, getXNList } from '../../api/system'
export default {
  data() {
    return {
      dialogFormVisible: false,
      keyValue: '',
      xn: '',
      xnlist: [],
      pagination: {
        rows: 15,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight - 100, // 实时屏幕高度
      dataPageList: [],
      tableloading: true,
      buttonauth: {},
      list: [],
      formdata: {},
    }
  },
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    getXNList().then((res) => {
      if (res.code === 200) {
        this.xnlist = res.data
        this.xn = res.data[0].ksnf + '-' + res.data[0].jsnf
        this.getDataPageList()
      }
    })
    this.getDataPageList()
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    search() {
      this.pagination.page = 1
      this.getDataPageList()
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      GetXSDFCXPageList({
        queryJson: JSON.stringify({
          xn: this.xn,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$refs.formRef.resetFields()
    },
    handleClick(row) {
      this.formdata = row
      studentGetListByXN({ XN: row.xn }).then((res) => {
        if (res.code === 200) {
          this.dialogFormVisible = true
          this.list = res.data
        } else {
        }
      })
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style  scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.btngroup {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  min-width: 600px;
  text-align: left;
}
.sm_search {
  padding: initial;
  border-top: 1px solid #ddd !important;
  background-color: #f3f3f3;
  width: 100%;
}
.sm_search_group {
  display: flex;
  padding: 5px 0 5px 0;
}
.sm_search_item {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}
.sm_search_title {
  min-width: 65px;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
  text-align: center;
  font-weight: 700;
}
>>> .el-table .el-table__body tr.el-table__row.current-row td,
.el-table__body tr.current-row > td,
.el-table__body tr.hover-row.current-row > td,
.el-table__body tr.hover-row.el-table__row.current-row > td,
.el-table__body tr.hover-row.el-table__row > td,
.el-table__body tr.hover-row > td {
  background-color: #409eff !important;
  color: white;
}
.table1 td {
  border: 1px solid #000;
  text-align: center;
  height: 34px;
  font-size: 14px;
}
table {
  border-collapse: collapse;
}

table .bold {
  font-weight: bold;
}
</style>
